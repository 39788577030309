import React from "react";
import "./InformationForm.css";
import photoForm from "../../../assets/images3/body/fotoFormulario2x.png";
import shoppingCart from "../../../assets/images3/body/Trazado 9617@2x.png";
import { ApiService } from "../../../shared/services/ApiCrud.service";
import { environment } from "../../../environment/environment";
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
    translator?: any;
}

class InformationForm extends React.Component <Props> {

    public apiService: ApiService = new ApiService();
    state = {
        // CAPTCHA
        isARobot: true,
        captchaValue: null,
        captchaToken: "sinToken",

        // TO VALIDATE BUTTONS
        contLogo: 0,
        contOnlinePayment: 0,

        // NORMAL FIELDS VALUE
        principalContact      : "",
        email                 : "",
        cellphoneNumber       : "",
        businessName          : "",
        commercialBusinessName: "",
        businessAddress       : "",
        externalNumber        : "",
        internalNumber        : "",
        postalCode            : "",
        website               : "",
        others                : "",
        employeeCode          : "",

        // FORM BUTTONS
        logo              : false,
        wantALogo         : false,
        onlinePayment     : false,
        termsAndConditions: false,
        privatePolicy     : false,

        // SELECTED FROM API
        stateSelected   : "Sin seleccionar",
        citySelected    : "Sin seleccionar",
        locationSelected: "Sin seleccionar",

        // STATES
        stateList: [],

        // CITIES
        cityList: [],

        // COLONIES
        locationList: [],

        // ERRORS
        errors: {},

        // IS FETCHED?
        isFetched: true,

        // CSS FIELDS (CLASSNAME)
        cPrincipalContact: "field",
        cEmail           : "field",
        cCellNum         : "field",
        cBusinessName    : "field",
        cCommercialName  : "field",
        cAddress         : "field",
        cState           : "select-information-form right-container",
        cLocation        : "select-information-form select-information-form",
        cCity            : "select-information-form",
        cExNum           : "field-two",
        cIntNum          : "field-two",
        cPostalCode      : "field-two",
        cZipCode         : "field-two",
        cWebsite         : "field",
        cEmployeeCode    : "field",

        // SPAN CSS CLASS
        cCellNumM         : "no-error-message",
        cPrincipalContactM: "no-error-message",
        cEmailM           : "no-error-message",
        cBusinessNameM    : "no-error-message",
        cCommercialNameM  : "no-error-message",
        cAddressM         : "no-error-message",
        cStateM           : "no-error-message",
        cCityM            : "no-error-message",
        cLocationM        : "no-error-message",
        cExNumM           : "no-error-message",
        cIntNumM          : "no-error-message",
        cZipCodeM         : "no-error-message",
        cWebsiteM         : "no-error-message",
        cEmployeeCodeM    : "no-error-message",

        //SPAN ERROR MESSAGE INFORMATION
        cCellNumError         : " ",
        cPrincipalContactError: " ",
        cEmailError           : " ",
        cBusinessNameError    : " ",
        cCommercialNameError  : " ",
        cAddressError         : " ",
        cStateError           : " ",
        cCityError            : " ",
        cLocationError        : " ",
        cExNumError           : " ",
        cIntNumError          : " ",
        cZipCodeError         : " ",
        cWebsiteError         : " ",
        cEmployeeCodeError    : " ",

        // MODAL WINDOW
        showModal         : false,
        showModalError    : false,
        modalErrorMessage : '',
    };

    ////////////////////////////////// CAPTCHA /////////////////////////////////////////////////

    ///////////////////////////////////////////////////// CHANGE CHECKED OF BUTTONS ////////////////////////////
    changeTermsAndConditions(): void {
        if (!this.state.termsAndConditions) {
            this.setState({ termsAndConditions: true });
        } else {
            this.setState({ termsAndConditions: false });
        }
    };

    changeCheckedLogo(): void {
        if (!this.state.wantALogo) {
            this.setState({ wantALogo: true });
        } else {
            this.setState({ wantALogo: false });
        }
    };

    changeCheckedPolitic(): void {
        this.setState({privatePolicy: !this.state.privatePolicy});
    };

    //////////////////////////////// VALIDATE ALL FIELDS ///////////////////////////////////////////////////////
    validFormulary(fields: any) {
        const errors: any = {}

        //////////////////////////// EMPTY //////////////////////////////7
        if (!fields.principalContact) {
            errors.principalContact = this.props.translator('errorPrincipalContact');
            this.setState({ cPrincipalContactError: this.props.translator('errorEmpty'),
                cPrincipalContactM: "error-message", cPrincipalContact : "field-error"});
        }

        if (!fields.email) {
            errors.email = this.props.translator('errorEmail');
            this.setState({ cEmailError: this.props.translator('errorEmpty'),
                cEmailM: "error-message",  cEmail: "field-error"});
        }

        if (!fields.cellphoneNumber) {
            errors.cellphoneNumber = this.props.translator('errorPhoneNumber');
            this.setState({ cCellNumError: this.props.translator('errorEmpty'),
                cCellNumM: "error-message", cCellNum : "field-error"});
        }

        if (!fields.businessName) {
            errors.businessName = this.props.translator('errorBusinessName');
            this.setState({ cBusinessNameError: this.props.translator('errorEmpty'),
                cBusinessNameM: "error-message", cBusinessName: "field-error"})
        }

        if (!fields.commercialBusinessName) {
            errors.commercialBusinessName = this.props.translator('errorCommercialBusinessName');
            this.setState({ cCommercialNameError: this.props.translator('errorEmpty'),
                cCommercialNameM: "error-message", cCommercialName: "field-error" });
        }

        if (!fields.businessAddress) {
            errors.businessAddress = this.props.translator('errorBusinessAddress');
            this.setState({ cAddressError: this.props.translator('errorEmpty'),
                cAddressM: "error-message", cAddress: "field-error" });
        }

        if (!fields.externalNumber) {
            errors.externalNumber = this.props.translator('errorExternalNumber');
            this.setState({ cExNumError: this.props.translator('errorEmpty'),
                cExNumM: "error-message", cExNum: "field-error-two"});
        }

        if (!fields.postalCode) {
            errors.postalCode = this.props.translator('errorZipCode');
            this.setState( {cZipCodeError : this.props.translator('errorEmpty'),
                cZipCodeM: "error-message", cZipCode: "field-error-two"});
        }

        if (fields.termsAndConditions == false) {
            alert(this.props.translator('errorTermsAndConditions'));
            errors.termsAndConditions = this.props.translator('errorTermsAndConditions');
        }

        if (fields.privatePolicy == false) {
            alert(this.props.translator('errorPrivatePolicy'));
            errors.privatePolicy = this.props.translator('errorPrivatePolicy');
        }

        if (fields.contLogo == 0) {
            alert(this.props.translator('errorLogo'));
            errors.logo = this.props.translator('errorLogo');
        }

        if (fields.contOnlinePayment == 0) {
            alert(this.props.translator('errorPayment'));
            errors.onlinePayment = this.props.translator('errorPayment');
        }

        if (fields.stateSelected == "Sin seleccionar") {
            errors.stateSelected = this.props.translator('errorState');
            alert(this.props.translator('errorState'));
            this.setState({ cStateError: this.props.translator('errorState'),
                cStateM: "error-message", cState: "select-information-error right-container" });
        }

        if (fields.citySelected == "Sin seleccionar") {
            errors.citySelected = this.props.translator('errorCity');
            alert(this.props.translator('errorCity'));
            this.setState({ cCityError: this.props.translator('errorCity'),
                cCityM: "error-message", cCity: "select-information-error" });
        }

        if (fields.locationSelected == "Sin seleccionar") {
            errors.locationSelected = this.props.translator('errorLocation');
            alert(this.props.translator('errorLocation'));
            this.setState({ cLocationError: this.props.translator('errorLocation'),
                cLocationM: "error-message", cLocation: "select-information-error" });
        }

        ////////////////////////////////// CAPTCHA //////////////////////////////////////////////////7
        if (this.state.isARobot) {
            errors.realizaCaptcha = this.props.translator('errorCaptcha');
            alert(this.props.translator('errorCaptcha'));
        }

        ////////////////////////////// INVALID /////////////////////////////////////////////////////////
        ///////////////////////////////// VALIDATE EMAIL /////////////////////////////////////////
        const patternEmail: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!patternEmail.test(fields.email)) {
            errors.emailInvalid = this.props.translator('invalidEmail');
        }

        ////////////////////////////// VALIDATE PHONE NUMBER ///////////////////////////////
        const phonePattern: RegExp = /^\d{10}$/;
        if (!phonePattern.test(fields.cellphoneNumber)) {
            errors.errTelefono = this.props.translator('invalidPhoneNumber');
        }

        ////////////////////////////// VALIDATE POSTAL CODE //////////////////////////////////////////////
        const pcPattern: RegExp = /^\d{5}$/;
        if (!pcPattern.test(fields.postalCode)) {
            errors.errPostalCode = this.props.translator('invalidZipCode');
        }

        ///////////////////////////  VALIDATE PRINCIPAL CONTACT ///////////////////////////////
        const patternPrincipalContact: RegExp = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
        if (!patternPrincipalContact.test(fields.principalContact)) {
            errors.invalidContact = this.props.translator('invalidPrincipalContact');
        }

        /////////////////////////////////////// VALIDATE RAZÓN SOCIAL ////////////////////////////
        const patternRazonSocial: RegExp = /^.{4,100}$/;
        if (!patternRazonSocial.test(fields.razonSocial)) {
            errors.invalidRazonSocial = this.props.translator('invalidBusinessName');
        }
        /////////////////////////////// VALIDATE COMMERCIAL NAME ///////////////////////////
        const pCommercialName: RegExp = /^.{4,100}$/;
        if (!pCommercialName.test(fields.commercialBusinessName)) {
            errors.invalidCommercialName = this.props.translator('invalidCommercialName');
        }

        ///////////////////////////// VALIDATE ADDRESS ///////////////////////////////////
        const pAddress: RegExp = /^.{4,200}$/;
        if (!pAddress.test(fields.businessAddress)) {
            errors.invalidAddress = this.props.translator('invalidAddress');
        }

        /////////////////////////// VALIDATE EXTERNAL NUMBER ////////////////////////////
        const pExNum: RegExp = /^.{1,100}$/;
        if (!pExNum.test(fields.externalNumber)) {
            errors.invalidExtNum = this.props.translator('invalidExtNum');
        }

        //////////////////////////// VALIDATE INTERNAL NUMBER ////////////////////////////
        const pIntNum: RegExp = /^.{1,100}$/;
        if (!pIntNum.test(fields.internalNumber) && fields.internalNumber.length > 0) {
            errors.invalidIntNum = this.props.translator('invalidIntNum');
        }

        //////////////////////////// VALIDATE  WEBSITE /////////////////////////////////
        const pWebsite: RegExp = /^.{4,150}$/;
        if (!pWebsite.test(fields.website) && fields.website.length > 0) {
            errors.invalidWebsite = this.props.translator('invalidWebsite');
        }

        const hasErrors = (errorKeys: any[]) => errorKeys.some((key) :boolean => errors[key] !== undefined);

        const mandatoryFields :string[] = [
            'principalContact',
            'businessAddress',
            'cellphoneNumber',
            'email',
            'externalNumber',
            'businessName',
            'commercialBusinessName',
            'postalCode',
            'stateSelected',
            'citySelected',
            'locationSelected'
        ];

        const invalidFields :string[] = [
            'invalidAddress',
            'invalidWebsite',
            'invalidContact',
            'invalidIntNum',
            'invalidExtNum',
            'invalidCommercialName',
            'invalidRazonSocial',
            'errTelefono',
            'errPostalCode'
        ];

        if (hasErrors(mandatoryFields)) {
            alert(this.props.translator('errorForm'));
        } else if (hasErrors(invalidFields)) {
            alert(this.props.translator('errorForm'));
        }

        return errors;
    };

    ///////////////////////////////////////////////// SEND FORM METHOD /////////////////////////////////////

    showPanel(): void {
        const {errors, ...withoutError} = this.state;

        const result = this.validFormulary(withoutError);

        this.setState({errors: result});

        if(!Object.keys(result).length) {
            let packageData = {

                contactName   : this.state.principalContact,
                contactEmail  : this.state.email,
                contactPhone  : this.state.cellphoneNumber,
                razonSocial   : "",
                commercialName: this.state.commercialBusinessName,
                street        : this.state.businessAddress,
                locationId    : this.state.locationSelected,
                externalNumber: this.state.externalNumber,
                internalNumber: this.state.internalNumber,
                zipCode       : this.state.postalCode,
                urlPlatform   : this.state.website,
                employeeCode  : this.state.employeeCode,
                others        : "",
                ownLogo       : "",
                wantALogo     : "",
                onlinePayment : "",

            };

            let body = {
                name          : this.state.businessName,
                commercialName: this.state.commercialBusinessName,
                contactName   : this.state.principalContact,
                contactPhone  : this.state.cellphoneNumber,
                contactEmail  : this.state.email,
                street        : this.state.businessAddress,
                internalNumber: this.state.internalNumber,
                externalNumber: this.state.externalNumber,
                zipCode       : this.state.postalCode,
                planId        : 1,
                colonyId      :this.state.locationSelected,
                requiresOnlinePayment: this.state.onlinePayment,
                hasLogo        : this.state.logo,
                requiresNewLogo: this.state.wantALogo,
                comments       : this.state.others,
                urlPlatform    : this.state.website,
                employeeCode   : this.state.employeeCode,
            };

            this.submitForm(body);
        } else {
            console.log('error inside form result', result);
        }
    };

    submitForm(body: any): void {
        this.apiService.postBasic(`${environment.api.server}${environment.api.context}/basic/accounts`, body).then(result => {
            if (result.status == undefined) {
               this.clearFormState();
            } else {
                this.setState({modalErrorMessage: result.message});
                this.setState({showModalError: true});
            }
        });
    };

    clearFormState(): void {
        this.setState({
            showModal: true,

            // Vaciar el estado por completo
            usCaptcha: "",
            contLogo: 0,
            contOnlinePayment: 0,
            principalContact: "",
            email: "",
            cellphoneNumber: "",
            businessName: "",
            commercialBusinessName: "",
            businessAddress: "",
            externalNumber: "",
            internalNumber: "",
            postalCode: "",
            website: "",
            employeeCode: "",
            others: "",
            logo: false,
            wantALogo: false,
            onlinePayment: false,
            termsAndConditions: false,
            privatePolicy: false,
            stateSelected: "Sin seleccionar",
            citySelected: "Sin seleccionar",
            locationSelected: "Sin seleccionar",
            stateList: [],
            cityList: [],
            locationList: [],
            errors: {},
            isFetched: true,
        });
    };

    /////////////////////////////////////// API METHODS, TO FILL COMBO BOXES //////////////////////////////

    ///////////////////////////////////// FILL REPUBLICAN STATES COMBO BOX /////////////////////////////////////
    componentDidMount(): void {
        this.apiService.getBasic(`${environment.api.server}${environment.api.context}/basic/federal-entities`).then(response => {
            this.setState({stateList: response, isFetched: false});
        }).catch(error => {
            console.log('Error from getBasic', error);
        });
    };

    /////////////////////////////////////// SELECT A STATE ANF FILL CITIES COMBO BOX /////////////////////////////////////////
    selectState(nState: string){
        let stateClass   : string ="cState";
        let stateMessage : string ="cStateM";

        if (nState != null)  {
            this.setState({[stateClass]: "select-information-form right-container"});
            this.setState({[stateMessage]: "no-error-message"});
        }

        const stateId: number = parseInt(nState, 10);
        if (!isNaN(stateId)) {
            this.setState({stateSelected: stateId});
            this.apiService.getBasic(`${environment.api.server}${environment.api.context}/basic/municipalities?federalEntityId=${nState}`).then(response => {
                this.setState({ cityList: response, locationSelected: null, locationList: [] });
            });
        } else {
            this.setState({stateSelected: null, cityList: [], locationSelected: null, locationList: []});
        }
    };

    //////////////////////////////////// SELECT A CITY AND FILL  COLONIES COMBO BOX //////////////////////////////////////////
    selectCity(nCity: string): void {
        let cityClass  : string ="cCity";
        let cityMessage: string ="cCityM";

        if (nCity != null) {
            this.setState({[cityClass]: "select-information-form"});
            this.setState({[cityMessage]: "no-error-message"});
        }

        const cityId: number = parseInt(nCity, 10);
        if (!isNaN(cityId)) {
            this.setState({citySelected: cityId});
            this.apiService.getBasic(`${environment.api.server}${environment.api.context}/basic/colonies?municipalityId=${nCity}`).then(response => {
                this.setState({locationList: response});
            });
        } else {
            this.setState({citySelected: null, locationList: []});
        }
    }

    //////////////////////////////////// SELECT A COLONIE ///////////////////////////////////////////////
    selectLocation(nLocation: string): void {
        let locationClass  : string ="cLocation";
        let locationMessage: string ="cLocationM";

        if (nLocation != null) {
            this.setState({[locationClass]: "select-information-form"});
            this.setState({[locationMessage]: "no-error-message"});
        }

        const locationId: number = parseInt(nLocation, 10);
        if (!isNaN(locationId)) {
            this.setState({locationSelected: locationId});
        } else {
            this.setState({locationSelected: null});
        }
    }

    /////////////////////////////////////// HAMDLE CHANGE ON FIELDS ///////////////////////////////////////////

    validFiledTwo(expression: any , input : any, changeField: string){
        let messageError: string = changeField + "M";
        let formatError : string = changeField + "Error";

        //A specific conditional is added here for the internal number input,
        // as can be seen. The goal is to change its class to 'error' if the condition in valid formulary is not meet
        //  but to remove the 'error' class if the field is empty again, since the internal number input is not mandatory

        if (changeField !== "cIntNum") {
            if (input.value === "") {
                this.setState({[changeField]: "field-error-two"});
                this.setState({[messageError]: "error-message"});
                this.setState({[formatError]: this.props.translator('errorEmpty')});
                return;
            }
        }

        if (expression.test(input.value)) {
            this.setState({[changeField]: "field-two"});
            this.setState({ [messageError]: "no-error-message"});

        } else {
            if (changeField === "cIntNum" && input.value.length === 0) {
                this.setState({ [changeField]: "field-two" });
                this.setState({ [messageError]: "no-error-message"});
            } else {
                this.setState({[changeField]: "field-error-two"});
                this.setState({[messageError]: "error-message"});
                this.setState({[formatError]: this.props.translator('invalidFormat')});
            }
        }
    }

    validField(expression: any , input : any, changeField: string){
        //A specific conditional is added here for the website input and employeeCode,
        // as can be seen. The goal is to change its class to 'error'
        // if the minimum number of characters is not met, but to remove the 'error'
        // class if the field is empty again, since the website input and the employeeCode are not mandatory

        let messageError: string = changeField + "M";
        let formatError : string = changeField + "Error";

        if (changeField !== "cWebsite" && changeField !== "cEmployeeCode") {
            if (input.value === "") {
                this.setState({[changeField]: "field-error"});
                this.setState({[messageError]: "error-message"});
                this.setState({[formatError]: this.props.translator('errorEmpty')});
                return
            }
        }

        if(expression.test(input.value)){
            this.setState({ [changeField]: "field" });
            this.setState({ [messageError]: "no-error-message"});
        } else {
            if ((changeField === "cWebsite" || changeField === "cEmployeeCode")  && input.value.length === 0) {
                this.setState({ [changeField]: "field" });
                this.setState({ [messageError]: "no-error-message"});
            } else {
                this.setState({[changeField]: "field-error"});
                this.setState({[messageError]: "error-message"});
                this.setState({[formatError]: this.props.translator('invalidFormat')});
            }
        }
    }

    handleChange(event: any): void {
        const expressions = {
            pPrincipalContact: /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
            pEmail           : /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            pCellNum         : /^\d{10}$/,
            pSocialReason    : /^.{4,100}$/,
            pCommercialName  : /^.{4,100}$/,
            pAddress         : /^.{4,200}$/,
            pIntNum          : /^.{1,100}$/,
            pExNum           : /^.{1,100}$/,
            pZipCode         : /^\d{5}$/,
            pWebsite         : /^.{4,150}$/,
            pEmployeeCode    : /^.{6,100}$/,

            user    : /^[a-zA-Z0-9_-]{4,16}$/,
            name    : /^[a-zA-ZÀ-ÿ\s]{1,40}$/,
            password: /^.{4,12}$/,
        };

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        // Meter el cambio al estado
        this.setState({[name]: value});

        // Validar el cambio en el campo
        switch(name){
            case "principalContact":
                this.validField(expressions.pPrincipalContact, target, 'cPrincipalContact');
                break;
            case "email":
                this.validField(expressions.pEmail, target, 'cEmail');
                break;
            case "cellphoneNumber":
                this.validField(expressions.pCellNum, target, 'cCellNum');
                break;
            case "businessName":
                this.validField(expressions.pSocialReason, target, 'cBusinessName');
                break;
            case "commercialBusinessName":
                this.validField(expressions.pCommercialName, target, 'cCommercialName');
                break;
            case "businessAddress":
                this.validField(expressions.pAddress, target, 'cAddress');
                break;
            case "internalNumber":
                this.validFiledTwo(expressions.pIntNum, target, 'cIntNum');
                break;
            case "externalNumber":
                this.validFiledTwo(expressions.pExNum, target, 'cExNum');
                break;
            case "postalCode":
                this.validFiledTwo(expressions.pZipCode, target, 'cZipCode');
                break;
            case "website":
                this.validField(expressions.pWebsite, target, 'cWebsite');
                break;
            case "employeeCode":
                this.validField(expressions.pEmployeeCode, target, 'cEmployeeCode');
                break;
        }
    };

    rechargerPaging(): void {
        this.setState({showModal: false});
        window.location.reload();
    };

    changeCaptcha = (): void => {
        this.setState({isARobot: false});
    };

    expiredCaptcha = (): void => {
        this.setState({isARobot: true});
    };

    render() {
        return (
            <div className="form-container">
                <div className = "main-container">
                    <div className = "image-container">
                        <img className = "photo" src = {photoForm} alt={"photo"}/>
                    </div>


                    {/*////////////////////////////////////////FORM///////////////////////////////////////////////// */}


                    <div className="question-main-container">
                        <p id="title">{this.props.translator("titleForm")}</p>
                        <p id="txt1">{this.props.translator("subtitleForm")}</p>
                        {/*///////////////////////////////////////////////// NORMAL FIELDS  ///////////////////*/}

                        <div className="inputsContainer">
                            <input className={this.state.cPrincipalContact} type="text" name="principalContact"
                                   value={this.state.principalContact} onChange={(event) => this.handleChange(event)}
                                   placeholder={this.props.translator("principalContact")}/>
                            <span className={this.state.cPrincipalContactM}> {this.state.cPrincipalContactError} </span>

                            {/*/////////////////////EMISOR DE EVENTO //////////////////////////////////////*/}

                            <input className={this.state.cEmail} type="text" name="email" value={this.state.email}
                                   onChange={(event) => this.handleChange(event)}
                                   placeholder={this.props.translator("yourEmail")}/>
                            <span
                                className={this.state.cEmailM}> {this.state.cEmailError + this.props.translator('emailExample')} </span>

                            <input className={this.state.cCellNum} type="text" name="cellphoneNumber"
                                   value={this.state.cellphoneNumber} onChange={(event) => this.handleChange(event)}
                                   placeholder={this.props.translator("phoneNumber")}/>
                            <span
                                className={this.state.cCellNumM}> {this.state.cCellNumError + this.props.translator('phoneExample')} </span>

                            <input className={this.state.cBusinessName} type="text" name="businessName"
                                   value={this.state.businessName} onChange={(event) => this.handleChange(event)}
                                   placeholder={this.props.translator("razonSocial")}/>
                            <span
                                className={this.state.cBusinessNameM}> {this.state.cBusinessNameError + this.props.translator('minimumCharacters')} </span>

                            <input className={this.state.cCommercialName} type="text" name="commercialBusinessName"
                                   value={this.state.commercialBusinessName} onChange={(event) => this.handleChange(event)}
                                   placeholder={this.props.translator("comercialName")}/>
                            <span
                                className={this.state.cCommercialNameM}> {this.state.cCommercialNameError + this.props.translator('minimumCharacters')}</span>

                            <input className={this.state.cAddress} type="text" name="businessAddress"
                                   value={this.state.businessAddress} onChange={(event) => this.handleChange(event)}
                                   placeholder={this.props.translator("direction")}/>
                            <span className={this.state.cAddressM}> {this.props.translator('invalidAddress')}</span>
                        </div>

                        {/*///////////////////////////// SELECTORS //////////////////////////////*/}
                        <div className="inputs_container">
                            <div className="selector-message">
                                {
                                    <select onChange={(e) => this.selectState(e.target.value)}
                                            className={this.state.cState}>
                                        <option value="selectState">{this.props.translator("state")}</option>
                                        {!this.state.isFetched ?
                                            this.state.stateList.map((state: any) =>
                                                <option value={state.identifier}>{state.name}</option>) : ' '}
                                    </select>
                                }
                                <span className={this.state.cStateM}> {this.state.cStateError} </span>
                            </div>

                            <div className="selector-message">
                                {
                                    <select onChange={(e) => this.selectCity(e.target.value)}
                                            className={this.state.cCity}>
                                        <option value="selectCity">{this.props.translator("city")}</option>
                                        {!this.state.isFetched ?
                                            this.state.cityList.map((city: any) =>
                                                <option value={city.identifier}>{city.name} </option>) : ' '}
                                    </select>
                                }
                                <span className={this.state.cCityM}> {this.state.cCityError} </span>
                            </div>
                        </div>

                        <div className="inputs_container">
                            <div className="selector-message">
                                {
                                    <select onChange={(e) => this.selectLocation(e.target.value)}
                                            className={this.state.cLocation}>
                                        <option value="selectColonie">{this.props.translator("colonie")}</option>
                                        {!this.state.isFetched ?
                                            this.state.locationList.map((location: any) =>
                                                <option value={location.identifier}>{location.name} </option>) : ' '}
                                    </select>
                                }
                                <span className={this.state.cLocationM}> {this.state.cLocationError} </span>
                            </div>

                            {/*/////////////////// NORMAL FIELDS ////////////////////////// */}

                            <div className="selector-message">
                                <input className={this.state.cExNum} type="text" name="externalNumber"
                                       value={this.state.externalNumber} onChange={(event) => this.handleChange(event)}
                                       placeholder={this.props.translator("extNum")}/>
                                <span className={this.state.cExNumM}> {this.state.cExNumError} </span>
                            </div>

                        </div>
                        <div className="inputs_container">
                            <div className="selector-message">
                                <input className={this.state.cIntNum} type="text" name="internalNumber"
                                       value={this.state.internalNumber} onChange={(event) => this.handleChange(event)}
                                       placeholder={this.props.translator("intNum")}/>
                                <span className={this.state.cIntNumM}> {this.state.cIntNumError} </span>
                            </div>

                            <div className="selector-message">
                                <input className={this.state.cZipCode} type="text" name="postalCode"
                                       value={this.state.postalCode} onChange={(event) => this.handleChange(event)}
                                       placeholder={this.props.translator("postalCode")}/>
                                <span className={this.state.cZipCodeM}> {this.state.cZipCodeError} </span>
                            </div>

                        </div>

                        <div className="selector-message">
                            <input className={this.state.cWebsite} type="text" name="website" value={this.state.website}
                                   onChange={(event) => this.handleChange(event)} placeholder={this.props.translator("website")}/>
                            <span className={this.state.cWebsiteM}> {this.state.cWebsiteError} </span>
                        </div>

                        <div className="selector-message">
                            <input className={this.state.cEmployeeCode} type="text"
                                   name="employeeCode" value={this.state.employeeCode}
                                   onChange={(event) => this.handleChange(event)} placeholder={this.props.translator("employeeCode")}/>
                            <span className={this.state.cEmployeeCodeM}> {this.state.cEmployeeCodeError} </span>
                        </div>

                        <br/> <br/>
                        {/*//////////////////////////////////// BUTTONS ///////////////////////////////////// */}

                        {/*///////////////////////////////// BOTON TIENES LOGO ///////////////////*/}
                        <br/>
                        <div className="question-container">
                            <label className="label-information-Form">{this.props.translator("hasALogo")}</label>
                            <div className="buttons_container">
                                <input type="radio" id="buttonBlack" name="accountLogo" value="si"
                                       onClick={(event) => this.setState({logo: true, contLogo: 1})}/>
                                <label className="eBtnSi" htmlFor="buttonBlack">{this.props.translator("yes")}</label>

                                <input type="radio" name="accountLogo" value="no" id="buttonWhite"
                                       onClick={(event) => this.setState({logo: false, contLogo: 1})}/>
                                <label className="eBtnNo" htmlFor="buttonWhite"> {this.props.translator("no")}</label>
                            </div>
                        </div>
                        <br/><br/>

                        {/*//////////////////////////////BOTON TE HACEMOS UN LOGO //////////*/}
                        <br/>
                        <div className="label-check-container">
                            <label className="label-if-dont">
                                {this.props.translator("wantALogo1")}
                            </label>
                            <input className="myCheckBoxLogo" type="checkbox" value="valor"
                                   onClick={() => this.changeCheckedLogo()}/>
                        </div>

                        <br/> <br/><br/>

                        {/*////////////////////////////BOTON DESEAS RECIBIR PAGOS EN LINEA///////////7*/}
                        <br/>

                        <div className="question-container">
                            <label className="label-information-Form">{this.props.translator("wantOnlinePayment")}</label>

                            <div className="buttons_container">
                                <input type="radio" name="onlinePayment" id="withPayments" value="si"
                                       onClick={(event) => this.setState({onlinePayment: true, contOnlinePayment: 1})}/>
                                <label className="eBtnSi" htmlFor="withPayments">{this.props.translator("yes")}</label>

                                <input type="radio" name="onlinePayment" id="noPayments" value="no"
                                       onClick={(event) => this.setState({onlinePayment: false, contOnlinePayment: 1})}/>
                                <label className="eBtnSi" htmlFor="noPayments">{this.props.translator("no")}</label>
                            </div>
                        </div>

                        <br/><br/>

                        <label className="label-if-so">{this.props.translator("ifYes")}</label>

                        <br/>
                        <br/>

                        {/*/////////////////////////// BOTON TERMINOS Y CONDICIONES //////////////////7*/}

                        <br/>
                        <div className="question-container">
                            <label className="label-if-so"
                                   onClick={() => this.changeTermsAndConditions()}> {this.props.translator("acceptTermsAndConditions")}<a
                                className="hrefs" href="https://mypopstoreapp.com/terminosycondiciones.html"
                                target="_blank">{this.props.translator("termsAndConditionsW")}</a></label>

                            <input className="myCheckBoxTerms" type="checkbox" checked={this.state.termsAndConditions} name="terms"
                                   value="valorOtro" onClick={() => this.changeTermsAndConditions()}/>
                        </div>
                        <br/><br/>

                        {/*//////////////////////// BOTON POLITICA DE PRIVACIDAD///////////77*/}
                        <div className="question-container">
                            <label className="label-if-so"
                                   onClick={() => this.changeCheckedPolitic()}> {this.props.translator("acceptPolicy")} <a
                                className="hrefs" href="https://mypopstoreapp.com/avisodeprivacidad.html"
                                target="_blank">{this.props.translator("privacyPolicyW")}</a></label>

                            <input className="myCheckBoxTerms" type="checkbox" checked={this.state.privatePolicy}
                                   name="privatePoli" value="unvalor" onClick={() => this.changeCheckedPolitic()}/>
                        </div>

                        {/*///////////////////////////////////////// CAPTCHA ///////////////////////////////////]*/}

                        <br/>
                        {/*<div className = "captcha">
                        <label className = "txtCaptcha">{this.state.captchaString}</label>
                        <br/>
                        <input type = "text" id = "myCaptcha"onChange={(event)=>this.setState({usCaptcha: event.target.value})}/>
                    </div>*/}

                        <div className="reCaptcha"><ReCAPTCHA sitekey="6LdcS6YdAAAAAD2lW-dy1diju4AP5QYxYpRhW41X"
                                                              onChange={this.changeCaptcha} onExpired={this.expiredCaptcha}/>
                        </div>

                        {/*///////////////////////BOTON REGISTRAR ////////////////*/}
                        <br/>

                        {/*//////////////////////////////////////////// EMISOR DE EVENTO ////////////////77*/}
                        <div className="send-button">
                            <button className="button"
                                    onClick={() => this.showPanel()}>{this.props.translator("buttonRegister")}</button>
                        </div>

                    </div>

                    {/*/////////////////////////////////////////////////MODAL///////////////////////////////////////////////// */}
                    {
                        (this.state.showModal)?
                            <div className = "first-container">
                                <div className = "modal-container">
                                    <div className = "all-container">
                                        <div className = "btn-cancel-container">
                                            <img id = "shopping-cart"src = {shoppingCart} />
                                            <button className = "cancel" onClick={(event)=>this.setState({showModal: false})}>X</button>
                                        </div>

                                        <div className = "indications-container">
                                            <p className = "title-indications"> {this.props.translator("welcomeModal")}</p>
                                            <p className = "p1"> {this.props.translator("thanksRegistration")} </p>
                                            <p className = "p2"> {this.props.translator("sendMail")}</p>
                                        </div>

                                        <div className = "button-container">
                                            <button className = "button-close-modal" onClick={(event)=>this.rechargerPaging()}>{this.props.translator("acceptModal")}</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }

                    {
                        (this.state.showModalError) ?
                            <div className="first-container">
                                <div className="modal-container">
                                    <div className="all-container">
                                        <div className="btn-cancel-container">
                                            <img id="shopping-cart" src={shoppingCart} alt={"shopping-cart"}/>
                                            <button className="cancel"
                                                    onClick={(event) => this.setState({showModalError: false})}>X
                                            </button>
                                        </div>

                                        <div className="indications-container">
                                            <p className="title-indications"> {this.props.translator("modalError")}</p>
                                            <p className="p1"> {this.state.modalErrorMessage} </p>
                                        </div>

                                        <div className="button-container">
                                            <button className="button-close-modal"
                                                    onClick={(event) => this.setState({showModalError: false})}>{this.props.translator("acceptModal")}</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            :
                            <></>
                    }

                </div>
            </div>
        );
    };
}

export default InformationForm;