import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
const footerLogo = require("../../../assets/images/footer/footerLogo.png");
const footerTw = require("../../../assets/images/footer/tw-icon.png");
const footerFb = require("../../../assets/images/footer/fb-icon.png");
const footerInsta = require("../../../assets/images/footer/insta-icon.png");

interface Props {
  translator?: any
}

class FooterComponent extends React.Component <Props> {
  render() {
    return (
      <footer className="footer">
        <div className="footer-items">
          <div className="logo-area">
          <img className="footer-logo" src={footerLogo} alt="" />
          </div>
          <div className="footer-links">
            <a className="footer-link" href="#QueEs">{this.props.translator('LinkTextOne')}</a>
            <a className="footer-link" href="#TuApp">{this.props.translator('LinkTextTwo')}</a>
            <a className="footer-link" href="#Planes">{this.props.translator('LinkTextThree')}</a>
            <a className="footer-link" href="#Contacto" >{this.props.translator('LinkTextFour')}</a>
            <Link  to="/faqs">FAQ</Link>
            <a href="terminosycondiciones.html" >{this.props.translator('LinkTextFive')}</a>
            <a className="contact-custom-button" href="/customer-admin" >{this.props.translator('LinkTextSeven')}</a>

          </div>
          <div className="social-links">
              {/*  <a href="https://www.facebook.com/BWL-TECH-GROUP-385319465527208/"><img alt="" className="icon-fb" src={footerFb}  /></a>
         <a href="https://twitter.com/BWLMX"> <img className="icon-tw" src={footerTw} alt="" /></a>
         <a href="https://twitter.com/BWLMX"> <img className="icon-insta" src={footerInsta} alt="" /></a> */}
          </div>
        </div>
        <div className="footer-copyright notice-privacy">
          <span >{this.props.translator('FooterTextFour')}<a href="avisodeprivacidad.html">{this.props.translator('FooterTextFour-2')}</a></span>
        </div>
        <div className="footer-copyright footer-url">
          <span >{this.props.translator('FooterTextOne')}<a href="https://www.bwl.com.mx/">{this.props.translator('FooterTextTwo')}</a>{this.props.translator('FooterTextThree')}</span>
        </div>
      </footer>
    );
  }
}
export default FooterComponent;
